var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"1200px","margin":"0 auto","background-color":"#F4F6FC"}},[_c('div',{staticClass:"banner-wrapper"}),_c('div',{staticClass:"tac mt-5 mb-3",staticStyle:{"background-color":"white","margin-top":"-1px"}},[_c('img',{staticStyle:{"margin-top":"40px"},attrs:{"src":require("@/assets/img/prefecture/remen.png"),"height":"64px"}}),_c('service',{attrs:{"cfItemList":_vm.cfItemList,"service-list":_vm.serviceList,"item-code":9002,"page-index":_vm.pageIndex,"page-size":_vm.pageSize,"total-count":_vm.totalCount},on:{"getDataList":_vm.getDataList}}),_c('el-divider'),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tac mt-5 mb-3"},[_c('img',{attrs:{"src":require("@/assets/img/prefecture/support.png"),"height":"64px"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-indent":"40px","line-height":"180%","margin-bottom":"20px","margin-top":"10px","padding":"10px 20px 10px 20px","text-align":"left"}},[_c('span',[_vm._v(" “公司宝”作为“汉唐信通（北京）科技有限公司”的战略核心品牌，依托“汉唐信通”16年丰富行业经验，是基于数据化智能化应用的“一站式数字化企业服务平台”， 拥有国家高新技术企业、中关村高新技术企业、北京市专精特新中小企业等证书，入选“国家中小企业公共服务示范平台”。 通过自主研发多款 SaaS 企业服务生态系统，将服务商与终端用户有效连接，并将智能化系统、标准化产品和流程赋能给生态伙伴服务商。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-indent":"40px","line-height":"180%","margin-bottom":"20px","margin-top":"10px","padding":"10px 20px 10px 20px","text-align":"left"}},[_c('span',[_vm._v(" 目前，公司宝的标准化企业服务产品数量已经超过500个。我们始终坚持用科技方式创新科技服务，核心成员均来自 IT、互联网行业的优秀人才，拥有员工 450 余名。 服务范围以北京、上海、广州、深圳、成都等14家分子公司为核心，业务范围覆盖全球。主营业务有：工商服务、人力资源服务、财税服务、知识产权服务、 资质审批服务、税收筹划服务、股权转让服务、海外业务等一站式行业套餐服务。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"line-height":"180%","margin-top":"10px","padding":"10px 20px 10px 20px","text-align":"left","color":"#FFC000","font-size":"20px"}},[_c('span',[_vm._v(" 服务客户75万+ ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-indent":"40px","line-height":"180%","margin-bottom":"20px","padding":"10px 20px 10px 20px","text-align":"left"}},[_c('span',[_vm._v(" 已成功为来自全国各地区的75万余家客户提供服务，客户包括百度、新浪、盛大、乐视等众多国内外知名跨国企业、三资企业、国营企业、民营企业，横跨通信、 电子、IT、金融、影视、文化传媒等多个行业。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"line-height":"180%","margin-top":"10px","padding":"10px 20px 10px 20px","text-align":"left","color":"#FFC000","font-size":"20px"}},[_c('span',[_vm._v(" 业务全球化覆盖 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-indent":"40px","line-height":"180%","margin-bottom":"20px","padding":"10px 20px 10px 20px","text-align":"left"}},[_c('span',[_vm._v(" 目前，公司宝业务已经覆盖欧洲、东南亚、中东、美洲等地区的数十个国家，服务范围覆盖全球主要经济发展区域。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("@/assets/img/prefecture/gsbditu.jpg"),"width":"1000px"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"line-height":"180%","margin-top":"10px","padding":"10px 20px 10px 20px","text-align":"left","color":"#FFC000","font-size":"20px"}},[_c('span',[_vm._v(" 公司资质 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("@/assets/img/prefecture/gsbzizhi1.jpg"),"width":"1000px"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("@/assets/img/prefecture/gsbzizhi2.jpg"),"width":"1000px"}})])
}]

export { render, staticRenderFns }