<template>
    <div style="width: 1200px;margin: 0 auto;background-color: #F4F6FC">
        <div class="banner-wrapper">
        </div>
        <div class="tac mt-5 mb-3" style="background-color: white;margin-top: -1px">
            <img src="~@/assets/img/prefecture/remen.png" height="64px" style="margin-top: 40px">
            <service :cfItemList="cfItemList" :service-list="serviceList" @getDataList=getDataList :item-code="9002"
                     :page-index="pageIndex" :page-size="pageSize" :total-count="totalCount"></service>

            <el-divider></el-divider>
            <div class="tac mt-5 mb-3">
                <img src="~@/assets/img/prefecture/support.png" height="64px">
            </div>

            <div style="text-indent: 40px;line-height: 180%;margin-bottom: 20px;margin-top: 10px;padding: 10px 20px 10px 20px;text-align:left;">
                                <span>
                                 “公司宝”作为“汉唐信通（北京）科技有限公司”的战略核心品牌，依托“汉唐信通”16年丰富行业经验，是基于数据化智能化应用的“一站式数字化企业服务平台”，
                                    拥有国家高新技术企业、中关村高新技术企业、北京市专精特新中小企业等证书，入选“国家中小企业公共服务示范平台”。
                                    通过自主研发多款 SaaS 企业服务生态系统，将服务商与终端用户有效连接，并将智能化系统、标准化产品和流程赋能给生态伙伴服务商。
                                </span>
                            </div>

            <div style="text-indent: 40px;line-height: 180%;margin-bottom: 20px;margin-top: 10px;padding: 10px 20px 10px 20px;text-align:left;">
                                <span>
                              目前，公司宝的标准化企业服务产品数量已经超过500个。我们始终坚持用科技方式创新科技服务，核心成员均来自 IT、互联网行业的优秀人才，拥有员工 450 余名。
                                    服务范围以北京、上海、广州、深圳、成都等14家分子公司为核心，业务范围覆盖全球。主营业务有：工商服务、人力资源服务、财税服务、知识产权服务、
                                    资质审批服务、税收筹划服务、股权转让服务、海外业务等一站式行业套餐服务。
                                </span>
            </div>
            <div style="line-height: 180%;margin-top: 10px;padding: 10px 20px 10px 20px;text-align:left;color: #FFC000;font-size: 20px">
                                <span>
                              服务客户75万+
                                </span>
            </div>
            <div style="text-indent: 40px;line-height: 180%;margin-bottom: 20px;padding: 10px 20px 10px 20px;text-align:left;">
                                <span>
                              已成功为来自全国各地区的75万余家客户提供服务，客户包括百度、新浪、盛大、乐视等众多国内外知名跨国企业、三资企业、国营企业、民营企业，横跨通信、
                                    电子、IT、金融、影视、文化传媒等多个行业。
                                </span>
            </div>
            <div style="line-height: 180%;margin-top: 10px;padding: 10px 20px 10px 20px;text-align:left;color: #FFC000;font-size: 20px">
                                <span>
                             业务全球化覆盖
                                </span>
            </div>
            <div style="text-indent: 40px;line-height: 180%;margin-bottom: 20px;padding: 10px 20px 10px 20px;text-align:left;">
                                <span>
                              目前，公司宝业务已经覆盖欧洲、东南亚、中东、美洲等地区的数十个国家，服务范围覆盖全球主要经济发展区域。
                                </span>

            </div>
            <div>
                    <img src="~@/assets/img/prefecture/gsbditu.jpg" width="1000px">
            </div>
            <div style="line-height: 180%;margin-top: 10px;padding: 10px 20px 10px 20px;text-align:left;color: #FFC000;font-size: 20px">
                                <span>
                             公司资质
                                </span>
            </div>
            <div>
                <img src="~@/assets/img/prefecture/gsbzizhi1.jpg" width="1000px">
            </div>
            <div>
                <img src="~@/assets/img/prefecture/gsbzizhi2.jpg" width="1000px">
            </div>
        </div>

<!--             <div class="jianjie p-5">-->
            <!--            <div class="tac fwb" style="font-size: 50px">-->
            <!--                公司简介-->
            <!--            </div>-->
            <!--            <div style="text-indent: 40px;line-height: 180%;margin-bottom: 20px;margin-top: 40px">-->
            <!--                <span>-->
            <!--                 公司宝成立于2014年，汉唐信通(北京)咨询股份有限公司旗下品牌。-->
            <!--                    主营业务有：工商服务、人力资源服务、财税服务、知识产权服务、资质审批服务、税收筹划服务、股权转让服务、海外业务等一站式行业套餐服务。-->
            <!--                </span>-->
            <!--            </div>-->
            <!--            <div style="text-indent: 40px;line-height: 180%;margin-bottom: 20px">-->
            <!--                <span>-->
            <!--“公司宝”作为汉唐信通(北京)咨询股份有限公司的战略核心品牌，是基于数据化、智能化应用的“全生命周期的一站式企业服务平台”。通过自主研发的SaaS企业服务生态系统，将服务商与终端用户有效连接，为用户提供500+项标准化服务产品。-->
            <!--                </span>-->
            <!--            </div>-->
            <!--        </div>-->
            <!--        <div class="zizhi p-5">-->
            <!--            <div class="fwb tac" style="font-size: 50px">-->
            <!--                获奖荣誉-->
            <!--            </div>-->
            <!--            <div style="text-indent: 40px;line-height: 180%;margin-bottom: 20px;margin-top: 40px">-->
            <!--                <span>-->
            <!--                    2016年1月，公司宝获“2015年度互联网责任品牌”称号-->
            <!--                </span>-->
            <!--            </div>-->
            <!--            <div style="text-indent: 40px;line-height: 180%;margin-bottom: 20px">-->
            <!--                <span>-->
            <!--                    2016年11月，公司宝入选全球知识产权生态大会“2016年度中国商标代理十强”-->
            <!--                </span>-->
            <!--            </div>-->
            <!--            <div style="text-indent: 40px;line-height: 180%;margin-bottom: 20px">-->
            <!--                <span>-->
            <!--                    2019年8月，公司宝获得GIEC2019第六届全球互联网经济大会“2018-2019年度互联网经济大奖”-->
            <!--                </span>-->
            <!--            </div>-->
            <!--            <div style="text-indent: 40px;line-height: 180%;margin-bottom: 20px">-->
            <!--                <span>-->
            <!--                    2020年8月29日，公司宝在第三届中国品牌经济峰会暨品牌经济指数发布及2020斯贝瑞奖颁奖典礼上获得"2020年度企业服务领军企业"荣誉奖项-->
            <!--                </span>-->
            <!--            </div>-->
            <!--            <div style="text-indent: 40px;line-height: 180%;margin-bottom: 20px">-->
            <!--                <span>-->
            <!--                    2020年10月，公司宝荣登2020北京民营企业中小百强榜单-->
            <!--                </span>-->
            <!--            </div>-->
            <!--            <div style="text-indent: 40px;line-height: 180%;margin-bottom: 20px">-->
            <!--                <span>-->
            <!--                    2020年12月，国际科创节暨全球数字大会，公司宝获评年度人工智能先锋奖-->
            <!--                </span>-->
            <!--            </div>-->
            <!--        </div>-->

    </div>
</template>

<script>
    import Banner from './component/banner'
    import Service from './component/service'

    export default {
        name: 'prefecture',
        components: {
            // Banner,
            Service
        },
        data() {
            return {
                bannerPath: require('../../../assets/img/prefecture/cqcBanner.png'),
                serviceList: [],
                cfItemList: [
                    {
                        itemCode: '9002',
                        itemName: '全部'
                    },
                    {
                        itemCode: '900201',
                        itemName: '新技术产品服务'
                    },
                    {
                        itemCode: '900202',
                        itemName: '扶持政策咨询'
                    },
                    {
                        itemCode: '900203',
                        itemName: '国家高新'
                    },
                    {
                        itemCode: '900204',
                        itemName: '专精特新'
                    },
                    {
                        itemCode: '900205',
                        itemName: '技术合同登记服务'
                    },
                    {
                        itemCode: '900206',
                        itemName: '科技型中小企业评价咨询服务'
                    },
                    {
                        itemCode: '900207',
                        itemName: '双软评估'
                    },
                    {
                        itemCode: '900208',
                        itemName: '中关村高新'
                    }
                ],
                pageIndex: 1,
                pageSize: 6,
                itemCode: '9002',
                totalCount: 0
            }
        },
        created() {
            this.prefectureKey = this.$route.params.prefectureKey
            this.prefectureName = this.$route.params.prefectureName
            // this.getCfItemList()
            this.getDataList('9002', 1, 8)
        },
        methods: {
            getCfItemList() {
                this.$http({
                    url: this.$http.adornUrl(this.$api.PUB.CF_ITEM),
                    methods: 'get',
                    params: this.$http.adornParams({
                        enterpriseId: 'a9f40762402c4b519f4c748a91824b43'
                    })
                }).then(({data}) => {
                    if (data && data.code === 0) {
                        this.cfItemList = data.list
                    }
                })
            },

            getDataList(itemCode, pageIndex, pageSize) {
                this.$http({
                    url: this.$http.adornUrl(this.$api.PUB.CQC_PRODUCT),
                    methods: 'get',
                    params: this.$http.adornParams({
                        page: pageIndex,
                        limit: pageSize,
                        itemCode: itemCode || null,
                        enterpriseId: 'b04f6b9abbe2492996ee644b558ba209'
                    })
                }).then(({data}) => {
                    if (data && data.code === 0) {
                        this.serviceList = data.page.list
                        this.pageIndex = pageIndex
                        this.pageSize = data.page.pageSize
                        this.totalCount = data.page.totalCount
                    } else {
                        this.dataList = []
                        this.totalCount = 0
                        this.totalPage = 1
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .jianjie {
        background-image: url("../../../assets/img/prefecture/topIntroduction.png");
        background-size: cover;
        height: 360px;
    }

    .zizhi {
        background-image: url("../../../assets/img/prefecture/topBackground.png");
        background-size: cover;
        height: 550px;
        padding: 80px 80px 40px 80px;
    }

    .dian {
        font-size: 30px;
        color: #ff8d0d;
        text-shadow: 2px 2px black;
    }
    .banner-wrapper {
        width: 100%;
        height: 500px;
        background-image: url("../../../assets/img/prefecture/gsb.png");
        background-size: cover;
    }
</style>
